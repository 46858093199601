// import { ElMessage } from 'element-plus'

import router from "@/router"
import { convertExamDate } from "../modules/helpers"

// import router from "@/router"
// import cookie from 'vue-cookies'

export const student = {
    state: () => ({
        student:{},
        oneStudent:{
            studyId:'',
            img:''
        },
        study:['Umumiy o`rta maktab','Kasb-hunar kolleji','Akademik litsey','Universitet'],
        studyName:['Umumiy o`rta maktab','Kasb hunar kolleji','Akademik litsey','Oliy ta`lim muassasi'],
        types: [
            {title: 'Kunduzgi', value: 0},
            {title: 'Sirtqi', value: 1}
        ],
        foreign:['Ingliz tili','Nemis tili','Fransuz tili'],
        country:[
            {name: "O'zbekiston Respublikasi", code: 'uz'}, 
            {name: "Qozog'iston Respublikasi", code: 'kz'}, 
            {name: "Tojikiston Respublikasi", code: 'tj'}, 
            {name: "Turkmaniston Respublikasi", code: 'tk'}, 
            {name: "Qirg'iziston Respublikasi", code: 'kg'}, 
            {name: "Boshqa", code: 'ot'}, 
        ],
        studyTypes:['Kunduzgi','Sirtqi'],
        studyPart:['Bakalavriat','Magistratura'],
        exams:[],
        statusList:  [
            {
                title: 'Qabul qilindi',
                type: 'success'
            },
            {
                title: 'Tekshiruvda',
                type: 'warning'
            },
            {
                title: 'Bekor qilindi',
                type: 'danger'
            },
            {
                title: 'Arxivda',
                type: 'warning'
            },
            {
                title: 'Talaba',
                type: 'success'
            },
        ]
    }),
    getters:{
        studyPart({studyPart}){
            return studyPart
        },
        statusList({statusList}){
            return statusList
        },
        exams({exams}){
            return exams
        },
        student({student}){
            return student
        },
        studyTypes(state){
            return state.studyTypes
        },
        studyName(state){return state.studyName},
        country(state){
            return state.country
        },
        foreign(state){
            return state.foreign
        },
        
        types(state){
            return state.types
        },
        study(state){
            return state.study
        },   
    },
    mutations:{
        student(state,payload){
            state.student = {...payload}
        },
        exams(state, payload) {
            state.exams = [
                ...payload.map((exam) => {
                    exam.date = convertExamDate(exam.date);
                    return exam;
                }),
            ];
        },
    },
    actions:{
        async getExams({dispatch,commit},payload){
            let res = await dispatch('getAxios',{ url: 'exam', search: payload })
            if (res.status == 200){
                commit('exams',[...res.data.exams])
            }
        },
        async getStudent({dispatch,commit}){
            commit('loading',true)
            let res = await dispatch('getAxios',{url:'cabinet/my'})
            if (res.status == 200){
                commit('loading',false)
                commit('student',{...res.data})
            }
        },
        async getMyProfile({dispatch}){
            return await dispatch('getAxios',{url:'cabinet/my'})
        },
        async saveMe({dispatch,commit},payload){
            let res = await dispatch('putAxios',{url:'cabinet/save',data:payload})
            if (res.status == 200){
                commit('student',{...res.data,role:'user'})
                router.push('/')
            }
        },
        removeFile({dispatch},payload){
            return dispatch('postAxios',{
                url: `student/files/delete`,
                data: {resFile: payload}
            })
        },
        sendSms({dispatch},payload){
            return dispatch('postAxios',{
                url: `student/sendsms   `,
                data: payload
            })
        },
        async getReport({dispatch}){
            return dispatch('getAxios',{
                url: `cabinet/report`
            })
        }
    }
}