export const userMenu = [
    {
        path:'/requests',
        icon:'document',
        name: 'request',
        title:'Ariza',
        component:() => import(/* webpackChunkName: "request" */ '../views/student/RequestList.vue'),
        meta: { role:['user'] },
    },
    {
        path:'/report',
        icon:'list',
        name: 'report',
        title:'Shartnoma',
        component:() => import(/* webpackChunkName: "request" */ '../views/student/ReportPage.vue'),
        meta: { role:['user'] },
    },
    
]

export const userLinks = [
    ...userMenu,
    {
        path:'/quiz/:id',
        name: 'quizpage',
        component:() => import(/* webpackChunkName: "request" */ '../views/student/QuizPage.vue'),
    },

]