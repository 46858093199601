
import { layoutApp } from './modules/layout'
import { auth } from './modules/auth'
import { helpers } from './modules/helpers'

import { student } from './data/student'
import { direction } from './data/direction'

import { testproccess } from './data/student/testproccess'
import { result } from './data/result'


export const modulesList = {
    modules: {
        layoutApp,
        auth, 
        helpers,
        student, 
        direction,
        testproccess,
        result
    }
}
