<template>
    <component :is="student.role"/>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeStudent from './student/HomeStudent.vue';
export default {
  name: 'HomeView',
  components: {
    'user': HomeStudent,
  },
  computed: {
    ...mapGetters([
      'student'
    ])
  },
  
}
</script>

<style lang="scss">
@import '@/styles/view/home.scss';
</style>
