import { createStore } from 'vuex'

import { modulesList } from './modules'

export default createStore({
  state: {
    // url: 'https://demoapi.umft.uz',
    url: 'https://qabulapi.auniver.uz',
    // url: 'http://localhost:3000',
    
    month:['Yanvar','Fevral','Mart','Aprel','May','Iyun','Iyul','Avgust','Sentabr','Oktabr','Noyabr','Dekabr'],
    loading:false,
    dark:false,
    status: ['Faol','Faol emas'],
    weekdays:['Dushanba','Seshanba','Chorshanba','Payshanba','Juma','Shanba']
  },
  getters: {
    dark(state){
      return state.dark
    },
    weekdays(state){
      return state.weekdays
    },
    status(state){
      return state.status
    },
    loading(state){
      return state.loading
    },
    url(state){
      return state.url
    },
    uri(state){
      return state.uri
    },
    month(state){
      return state.month
    }
  },
  mutations:{
    dark(state,payload){
      state.dark = payload
    },
    loading(state,payload){
      state.loading = payload
    }
  },
  modules: {
    modulesList
  }
})
