import axios from "axios"

export const direction = {
    state: () => ({
        direction: [],
        onedirection:{},
        activDirection:[],
        statistika: [],
        magstatistika: [],
        gender: [],
        maggender: [],
        todaycount:0
    }),
    getters: {
        
        direction(state){
            return state.direction
        },
        
    },

    // mutations
    mutations:{
        direction(state,payload){
            state.direction = payload
        },
        
    },


    actions:{
        
        allDirection(context){
            axios.get(`${context.getters.url}/direction`,{
                headers: context.getters.header
            })
            .then(res=> {
                if (res.status==200){
                    context.commit('direction',res.data)
                }
            })
        },

        
    }
}