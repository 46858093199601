import { addZero } from "@/store/modules/helpers";

export const testproccess = {
  state: () => ({
    testproccesss: [],
    testproccess: {
      type: '',
      // trycount:0
    },
  }),
  getters: {
    testproccess({testproccess}) {
      return testproccess;
    },

  },
  mutations: {

    testproccess(state, payload) {
        state.testproccess = {...payload}
        state.testproccess.result = state.testproccess.result || {
          endtrycount : 0
        }
        state.testproccess.ball = payload.results?.balls > 0 ? (payload.results.balls / payload.results.allballs) * 100 : 0;
        let time = new Date(state.testproccess.time)
        state.testproccess.time = `${addZero(time.getHours())}:${addZero(time.getMinutes())}`

        state.testproccess.testsCount = 0;
        if (payload.testpack?.settings) {
          payload.testpack.settings.forEach((setting) => {
            if (setting.themes) {
              setting.themes.forEach((theme) => {
                state.testproccess.testsCount += theme.gencount;
              });
            }
          });
        }
    },
  },
  actions: {
    async getTestproccess(context) {

      let res = await context.dispatch('getAxios', {
        url: 'testproccess/student',
      });
      if (res.status == 200) {
        
        context.commit('testproccess', res.data);
      }
    },
    
  },
};
